import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class CartService {

      applyDiscountCode(cartId, couponcode){
            let url = API_URL +'user/cart/' + cartId + '/apply-code/'+couponcode;
            return apiService.get(url);
      }

      payOffline(cartId, data){
            let url = API_URL + 'user/payment/offline/'+cartId+'?payer_address='+data.payer_address
            return apiService.get(url);
      }

      khalti(cartId, data){
            let url  = API_URL + 'user/payment/khalti/'+cartId;
            return apiService.post(url, data);
      }

      paymentVerify(data){
            let url  = API_URL + 'user/payment/khalti-verify';
            return apiService.post(url, data);
      }

      esewa(cartId, data){
            let url  = API_URL + 'user/payment/esewa/'+cartId;
            return apiService.post(url, data)
      }

      initiatePaypal(cartId, data){
            let url  = API_URL + 'user/payment/initiate/paypal/'+cartId;
            return apiService.post(url, data)
      }

      paypal(cartId, data){
            let url  = API_URL + 'user/payment/paypal/'+cartId;
            return apiService.post(url, data)
      }

      stripe(cartId, data){
            let url  = API_URL + 'user/payment/stripe/'+cartId;
            return apiService.post(url, data)
      }

      free(cartId, data){
            let url  = API_URL + 'user/payment/checkout/'+cartId;
            return apiService.post(url, data)
      }

      checkDomainInCart(domain_name){
            let url  = API_URL + 'user/cart/domain/check';
            return apiService.post(url, {domain_name:domain_name})
      }

      orderReport(filter){
            let url  = API_URL + 'user/order/report/generate';
            if (filter != null && filter != undefined && filter != "") {
                  var queryString = Object.keys(filter).map((key) => {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
                  }).join('&');
                  if (queryString.length != 0)
                      url = url + '?' + queryString;
              }
            return apiService.get(url)
      }


      getPendingInvoice(){
            let url  = API_URL + 'user/cart/pending/invoice';
            return apiService.post(url)
      }

      getDomainExistWithPlanInCart(domain){
            let url  = API_URL + `user/cart/${domain}/check/domain-has-plan`;
            return apiService.get(url)
      }

      addServicesToCart(data){
            let url  = API_URL + `user/cart/add-to-cart/services`;
            return apiService.post(url, data)
      }

      checkServiceExistInCart(domainName){
            let url  = API_URL + `user/cart/check/service/${domainName}`;
            return apiService.get(url)
      }
}
