<template>
  <v-app>
    <v-container>
      <v-card flat>
        <v-card-text>
          <h3>Request professional service for <strong>{{ domain_name }}</strong></h3>
          <v-row>
            <v-col cols="6" sm="12" md="8" lg="8" class>
              <v-row>
                <v-col cols="6" sm="12" md="4" lg="4" v-for="(p,i) of professional_services" :key="i">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <h4 class="mt-2 font-weight-bold">{{ p.title }}</h4>
                          <p class="font-weight-bold" v-if="p.price">
                            {{ `${currency_symbol} ${p.price}` }}
                          </p>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox
                              v-if="p.price && !service_exist_in_cart"
                              outlined
                              dense
                              class="pull-right"
                              :readonly="selected.includes(i)"
                              v-model="selected"
                              :value="i"
                              @change="onProductSelect(p, i)"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <div class="offset-10">
                        <button class="btn text-right" type="button" data-toggle="collapse"
                                :data-target="`#collapse${p.id}`" aria-expanded="false"
                                :aria-controls="`collapse${p.id}`">
                          <i class="fas fa-chevron-circle-down"></i>
                        </button>
                      </div>
                      <div class="collapse" :id="`collapse${p.id}`">
                        <div class="card card-body">
                          {{ `${p.description}` }}
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
              <span class="text-danger display-1" v-if="service_exist_in_cart">Service request already exist in cart</span>
            </v-col>
            <v-col cols="6" sm="12" md="4" lg="4" v-if="order_list.length>0">
              <v-card flat>
                <v-card-text>
                  <h3>Order List</h3>
                  <hr class="border"/>
                  <v-row v-for="(order, i) of order_list" :key="i">
                    <v-col>
                      <h4 class="mt-2 font-weight-bold">
                        {{ order.title }}
                        <span
                            class="pull-right"
                            style="cursor:pointer"
                            @click="removeItem(i)"
                        >
                                              <i class="fas fa-trash"></i>
                                            </span>
                      </h4>
                      <p class="font-weight-bold" v-if="order.price">
                        {{ ` ${currency_symbol}${order.price}` }}
                        <!--                                                  {{`${p.symbol} ${p.price}`}}-->
                      </p>

                    </v-col>
                  </v-row>
                  <h3 class="mt-2s">Summary</h3>
                  <hr class="border"/>
                  <v-row>
                    <v-col cols="12" class="mr-2">
                      <h4>
                        Sub Total
                        <span class="pull-right">{{ `${currency_symbol}${sub_total}` }}</span>
                      </h4>
                    </v-col>
                    <v-col cols="12" class="mr-2">
                      <h4>
                        Tax
                        <span class="pull-right">{{ `${currency_symbol}${tax}` }}</span>
                      </h4>
                    </v-col>
                    <v-col cols="12" class="mr-2">
                      <h4>
                        Total
                        <span class="pull-right">{{ `${currency_symbol}${total}` }}</span>
                      </h4>
                    </v-col>
                    <hr class="border"/>
                    <v-col cols="12" class="mr-2 text-right">
                      <button class="btn btn-success" @click="addToCart()">Add To Cart</button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!--      <order-form-->
      <!--          :dialog="dialog"-->
      <!--          :country_id="country_id"-->
      <!--          :order_list="order_list"-->
      <!--          :isBusy="isBusy"-->
      <!--          @close_dialog="closeDialog"-->
      <!--          @create_order="createOrder"-->
      <!--      ></order-form>-->
    </v-container>
  </v-app>
</template>

<script>
import ProfessionalServiceService from "@/services/Websites/professiona-service/ProfessionalServiceService";
import ConfigService from "@/services/config/ConfigService";
import CartService from "@/services/cart/CartService";

const professionalService = new ProfessionalServiceService();
const config = new ConfigService();
const cartService = new CartService();

export default {
  name: "professional-service",
  data() {
    return {
      professional_services: [],
      selected: [],
      order_list: [],
      sub_total: 0,
      tax: 0,
      currency_symbol: null,
      total: 0,
      config: {},
      service_exist_in_cart: false
    }
  }, computed: {
    domain_name() {
      return this.$route.params.domain_name
    }
  }, mounted() {
    this.getAll()
    this.getSetting()
    this.checkServiceExistInCart()
  },
  methods: {
    getAll() {
      professionalService.all().then(response => {
        this.professional_services = response.data
      })
    }, getSetting() {
      config.getConfigByUser().then(response => {
        this.config = response.data.config
        this.tax = this.config.tax_percentage
        this.currency_symbol = this.config.currency_symbol
      })
    }, onProductSelect(product, index) {
      let p = {
        title: product.title,
        price: product.price,
        id: product.id,
      };
      this.order_list.push(p);
      this.calculateTotalsAndOther()
    }, removeItem(index) {
      this.order_list.splice(index, 1)
      this.selected.splice(index, 1)
      this.calculateTotalsAndOther()
    }, calculateTotalsAndOther() {
      this.sub_total = 0
      this.order_list.forEach(ele => {
        this.sub_total += parseFloat(ele.price)
      })
      this.tax = parseFloat(this.sub_total) * 0.13;
      this.total = (parseFloat(this.sub_total) + parseFloat(this.tax))
    }, checkServiceExistInCart() {
      this.service_exist_in_cart = false
      cartService.checkServiceExistInCart(this.domain_name).then(response => {
        if (response.data) {
          this.service_exist_in_cart = true;
        }
      })
    },
    addToCart() {
      let cart = this.buildCart()
      cartService.addServicesToCart(cart).then(response => {
        this.$snotify.success("Added to cart");
        this.checkServiceExistInCart();
      })
    },
    buildCart() {
      let items = [];
      let title = ''
      title += this.order_list.map((e) => {
        return e.title
      })
      let cart = {}
      this.order_list.forEach(ele => {
        cart = {
          service_list: title,
          item_type: "service",
          domain_name: this.domain_name,
          purchase_type: "new",
          terms: 1,
          item_price: this.total,
          tax: this.tax,
          sub_total: this.sub_total,
          is_processed: 0,
          currency_code: this.config.currency_code
        }
      })
      return cart
    }
  }
}
</script>

<style scoped>

</style>