import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class ConfigService {


    #api;

    constructor() {
        this.#api = `${API_URL}user/store-config`;
    }

    getConfigByUser(){
        let url = `${this.#api}/by-user`;
        return apiService.get(url);
    }
}
